<template>
  <v-menu
    ref="menu"
    v-model="activator"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
  >
    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope"
    /></template>
    <v-date-picker
      locale="ja"
      :day-format="formatDay"
      v-model="_date"
      v-bind="$attrs"
      no-title
      scrollable
    >
    </v-date-picker>
  </v-menu>
</template>
<script>
import dayjs from "@/plugins/dayjs";
export default {
  props: ["value"],
  computed: {
    _date: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  data() {
    return {
      activator: false
    };
  },
  methods: {
    formatDay(date) {
      return dayjs(date).format("D");
    }
  }
};
</script>
