<template>
  <v-row v-frag>
    <v-col cols="2" class="text-right font-14px text-6e6b7b">
      {{ label }}
    </v-col>
    <v-col :cols="cols - 2">
      <slot />
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    cols: {
      type: Number,
      required: false,
      default: 6
    }
  }
};
</script>
<style lang="scss" scoped>
p {
  color: #6e6b7b;
}
.label {
  width: 100px;
  height: 47px;
  transform: translateY(-3px);
}
</style>
