var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    directives: [{
      name: "frag",
      rawName: "v-frag"
    }]
  }, [_c('v-col', {
    staticClass: "text-right font-14px text-6e6b7b",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]), _c('v-col', {
    attrs: {
      "cols": _vm.cols - 2
    }
  }, [_vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }