<template>
  <div>
    <Parent />
    <v-card class="mt-3">
      <div class="d-flex justify-space-between pt-5 ml-4 mb-5 align-center">
        <v-card color="#525252" flat height="29px" width="163px" class="ml-4">
          <div class="d-flex justify-center align-center">
            <div class="font-18px white--text">
              取引先の基本情報
            </div>
          </div>
        </v-card>
        <div class="mr-7">
          <v-icon
            v-if="showEditForm"
            @click="showEditForm = !showEditForm"
            size="12"
            >$arrowUp</v-icon
          >
          <v-icon
            v-if="!showEditForm"
            @click="showEditForm = !showEditForm"
            size="12"
            >$arrowDown</v-icon
          >
        </div>
      </div>
      <div class="divider"></div>
      <SupplierForm
        v-if="showEditForm"
        :customerName.sync="fields.business_partner_name"
        :address.sync="fields.address"
        :displayName.sync="fields.display_name"
        :homePage.sync="fields.home_page"
        :telephoneNumber.sync="fields.telephone_number"
        :personInChargeName.sync="fields.contact_person"
        :memo.sync="fields.note"
        :selectedCategory.sync="category"
      />
      <div class="ml-8" v-if="showEditForm">
        <v-btn @click="openDeleteSupplierDialog" text color="#790000"
          >削除</v-btn
        >
      </div>
      <v-card-actions v-if="showEditForm" class="justify-center mt-n9 pb-6">
        <v-btn
          @click="confirmEditSupplier"
          color="primary"
          width="84px"
          height="24px"
          >登録</v-btn
        >
      </v-card-actions>
    </v-card>
    <Cashbook class="mt-5" :partner="$route.params.id"></Cashbook>
    <AccountsPayAble class="mt-5" :partner="$route.params.id"></AccountsPayAble>
  </div>
</template>

<script>
import SupplierForm from "@/components/admin/partials/Supplier/SupplierForm";
import Cashbook from "@/views/admin/CashBook/Index.vue";
import AccountsPayAble from "@/views/admin/PayableAccounts/Index";
import Parent from "@/views/admin/Store/Index";
import { mapGetters } from "vuex";
export default {
  components: {
    SupplierForm,
    Cashbook,
    AccountsPayAble,
    Parent
  },
  data() {
    return {
      barDisplay: false,
      showEditForm: true,
      category: [],
      fields: {
        business_partner_name: "",
        address: "",
        display_name: "",
        home_page: "",
        telephone_number: "",
        contact_person: "",
        note: "",
        category: []
      }
    };
  },
  async mounted() {
    await this.$store
      .dispatch("SUPPLIER_GET", {
        id: this.$route.params.id
      })
      .catch(() => {
        this.$router.go(-1);
      });
    this.fillData();
  },
  computed: {
    ...mapGetters(["getSupplier", "getMasterData"])
  },
  methods: {
    fillData() {
      for (const temp in this.getSupplier) {
        this.fields[temp] = this.getSupplier[temp];
      }
      for (let i = 0; i < this.fields.category.length; i++) {
        let temp = this.getMasterData.partners_category.find(
          partner => partner.id === this.fields.category[i]
        );
        this.category.push(temp);
      }
    },
    confirmEditSupplier() {
      this.fields.id = this.$route.params.id;
      this.fields.shop_id = this.$route.params.shop_id;
      this.fields.category = this.category.map(item => item.id);
      this.$store.dispatch("SUPPLIER_UPDATE", this.fields).then(() => {
        this.$store.dispatch("ALERT", {
          show: true,
          text: "編集内容を保存しました。"
        });
        this.$router.go(-1);
      });
    },
    openDeleteSupplierDialog() {
      this.$root.confirm
        .open("この取引先を\n削除してもよろしいですか？", { color: "red" })
        .then(confirm => {
          if (confirm) {
            this.$store
              .dispatch("SUPPLIER_DELETE", {
                id: this.$route.params.id
              })
              .then(() => {
                this.$router.go(-1);
              });
          }
        });
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
