var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    ref: "menu",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "max-width": "290px"
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(scope) {
          return [_vm._t(slot, null, null, scope)];
        }
      };
    })], null, true),
    model: {
      value: _vm.activator,
      callback: function callback($$v) {
        _vm.activator = $$v;
      },
      expression: "activator"
    }
  }, [_c('v-date-picker', _vm._b({
    attrs: {
      "locale": "ja",
      "day-format": _vm.formatDay,
      "no-title": "",
      "scrollable": ""
    },
    model: {
      value: _vm._date,
      callback: function callback($$v) {
        _vm._date = $$v;
      },
      expression: "_date"
    }
  }, 'v-date-picker', _vm.$attrs, false))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }